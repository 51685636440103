@import '@ctrl/ngx-emoji-mart/picker';

html,
body {
   height: 100%;
   box-sizing: border-box;
}
body {
   margin: 0;
}
.max-height-80 {
   max-height: 80px;
}
.pre-wrap {
   white-space: pre-wrap;
}
.text-ellipse {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}
@mixin flex($direction, $align-items, $justify-content) {
   display: flex;
   flex-direction: $direction;
   align-items: $align-items;
   justify-content: $justify-content;
}
@mixin full-width {
   width: 100%;
}
@mixin full-height {
   height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
/* Firefox */
input[type='number'] {
   -moz-appearance: textfield;
}
.horizontal-scroll {
   width: 100%;
   overflow-x: auto;
   white-space: nowrap;
   padding-bottom: 17px;
   box-sizing: content-box;

   &::-webkit-scrollbar {
      display: none;
   }
}
.toast-container {
   pointer-events: initial !important;
   min-width: 300px !important;
   text-align: center;
   z-index: 9999999;
   left: 15px;
   .toast-body {
      padding: 1em !important;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
   }
   &.success {
      .toast-body {
         border-color: #10d876;
      }
   }
   &.fail {
      .toast-body {
         border-color: #e50202;
      }
   }
   &.warning {
      .toast-body {
         border-color: #fe9431;
      }
   }
   &.info {
      .toast-body {
         border-color: var(--color-primary);
      }
   }
}

@media screen and (max-width: 769px) {
   .toast-container {
      left: 50%;
      transform: translateX(-50%);
   }
}
.tox-promotion,
.tox-statusbar__branding {
   display: none !important;
}
@import './assets/scss/main.scss';

// rtl changes
.rtl {
   direction: rtl !important;
   text-align: right;
   font-family:
      IRANYekan,
      IRANYekanDigits,
      'Work Sans',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Helvetica,
      Arial,
      sans-serif !important;
   * {
      &:not(i) {
         direction: rtl !important;
         text-align: right;
         font-family:
            IRANYekan,
            IRANYekanDigits,
            'Work Sans',
            -apple-system,
            BlinkMacSystemFont,
            'Segoe UI',
            Roboto,
            Helvetica,
            Arial,
            sans-serif !important;
      }
   }
   .ltr {
      * {
         &:not(i) {
            direction: ltr !important;
            text-align: unset !important;
            font-family:
               'Work Sans',
               -apple-system,
               BlinkMacSystemFont,
               'Segoe UI',
               Roboto,
               Helvetica,
               Arial,
               sans-serif !important;
         }
      }
   }

   &.modal-header {
      button {
         margin: calc(-0.5 * var(--bs-modal-header-padding-y)) auto calc(-0.5 * var(--bs-modal-header-padding-y))
            calc(-0.5 * var(--bs-modal-header-padding-x));
      }
   }

   input {
      font-size: 13px;
   }

   .form-select {
      padding: 0.375rem 0.75rem 0.375rem 2.25rem;
      padding-top: 1.625rem;
      background-position: left 0.75rem center;
   }

   .form-floating {
      label {
         left: unset;
         right: 0;
      }

      &:has(.form-select) {
         label {
            padding-right: 0;
         }
      }

      .form-select {
         padding-top: 1.625rem;
         padding-bottom: 0.625rem;
      }
   }

   .form-control:not(:placeholder-shown) ~ label {
      transform-origin: top right;
   }

   ul {
      li {
         padding-right: 2em !important;
         i {
            left: unset !important;
            right: 6px;
         }
      }
   }
}
.modal-bg-white {
   .modal-content {
      background: #f5f5f5;
   }
}
.full-screen-media {
   .modal-content {
      background: #151515;
   }
   .modal-header {
      position: absolute !important;
      right: 0 !important;
      top: 0 !important;
      padding-right: 1em;
      z-index: 9 !important;
   }
   .modal-body {
      padding: 0;
      height: 100vh;

      img,
      .img,
      video {
         height: 100vh;
         display: block;
         margin: auto;
         max-height: 100%;
         max-width: 100%;
         object-fit: contain !important;
      }
      video {
         height: calc(100vh - 8px);
         max-height: calc(100% - 8px);
      }
   }
   .modal-footer {
      display: none;
   }
   .audio-player {
      height: 60px;
      display: block;
      width: 100%;
      background-color: #000;
      padding: 5px 0;

      app-media-player {
         display: inherit;
         width: 85%;
         margin: 0 auto;
      }
   }

   #full-screen-slide.audio-player-enabled {
      height: calc(100vh - 60px);
      img {
         height: calc(100vh - 60px);
      }
   }
}
