.contact-wrap {
    z-index: 1;
    top:-100px;
    padding: 60px 80px 70px 80px;
    @include media-breakpoint-down(lg) {
        padding: 50px;
    }
    @include media-breakpoint-down(md) {
        padding: 40px 30px;
        .display3-size {font-size: 40px !important; margin-bottom: 30px !important;  }
    }
}

.contact-wrapper {
    padding: 80px;
    max-width: 550px;
    @include media-breakpoint-down(lg) {
        padding: 50px;
        max-width: 500px;
        margin-top: 80px;
        margin-bottom: 50px;
    }
}

.video-btn {
    display: block;
    position: relative;
    width: 100%;
    float: left;
    &:after {
        content: '';
        position: absolute;
        top:50%;
        left:50%;
        z-index: 1;
        margin-left: -15px;
        margin-top: -15px;
        background-image: url(../../images/play.png);
        background-size: contain;
        display: block;
        width: 30px;
        height: 30px;
    }
    &:before {
        content: '';
        position: absolute;
        top:50%;
        left:50%;
        z-index: 1;
        margin-left: -35px;
        margin-top: -35px;
        display: block;
        width: 70px;
        height: 70px;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50px;

    }
}

.video-bttn {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top:50%;
        left:50%;
        z-index: 1;
        margin-left: -30px;
        margin-top: -30px;
        background-image: url(../../images/icon-play.png);
        background-size: contain;
        display: block;
        width: 60px;
        height: 60px;
    }
    &.default {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50% !important;
        animation: videobtn 1.9s linear infinite;
        display: inline-block;
        width: 60px;
        height: 60px;

        &:after {margin-left: 0; position: relative; left:0;}
    }
}




.question-div {
    transition: all 0.4s ease;
    .question-ans {
        cursor: pointer;
        transition: all 0.4s ease;
        &.style2 {
           border:2px solid transparent !important;
        }
        &.style1.active {
            background-color: var(--theme-color) !important;
            color: #fff !important;
        }
        &.style2.active {
            border:2px solid var(--theme-color) !important;
        }
        &.style3 span {
            transition: all 0.4s ease;
        }
        &.style3.active span {
            background-color: var(--theme-color) !important;
            color: #fff !important;
        }
    }
}






.triangle-after {
    position: relative;
    &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 20px 0 0;
        border-color: #fff transparent transparent transparent;
        content: '';
        position: absolute;
        left:0;
        bottom:-20px;
        z-index: 2;
    }
}




.modal-popup-body,
.chat-wrapper,
.chat-left {
    height: calc(100vh - 120px);
    &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #f1f1f1;
    }
    &::-webkit-scrollbar {
        width: 4px;
        background-color: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ddd;
    }
}




.arrow-right {
    &:after {
        // background-image: url(../../images/arrow.png);
        width: 50px;
        right: -30px;
        z-index: 2;
        position: absolute;
        height: 17px;
        background-size: contain;
        background-position: center center;
        display: inline-block;
        content: '';
        position: absolute;
        top: 50px;
        background-repeat: no-repeat;
        @include respond("md") {
            display: none;
        }
    }

}


.nav-tabs {
    li {
        a {
            transition: all 0.4s ease;
            &.active {
                border-bottom:2px #333 solid !important;
                color: #000 !important;
            }
        }
    }
}

.login-card {min-width: 380px; max-width: 400px;  }
.coming-soon-card {min-width: 500px; max-width: 500px;  }
.banner-slider {
    .style-div {
        height: 570px;
        @include media-breakpoint-down(lg) {
            height: 450px;
        }
    }
}


.recent-post  {
    padding-left: 20px;
    li {
        list-style-type: disc;
        margin-bottom: 5px;
        a {font-size: 14px; font-weight: 500; color: #777;}
   }
}

.next-article:before {
    display: block !important;
    content: "";
    width: 100%;
    height: 2px;
    top: 0px;
    left: 0;
    position: absolute;
    z-index: 1;
    background: linear-gradient(to right,#FFF 0,#eeeeee 35%,#eeeeee 65%,#FFF 100%);
    background: -ms-linear-gradient(left,#fff 0,#eeeeee 35%,#eeeeee 65%,#fff 100%);
    background: -o-linear-gradient(left,#fff 0,#eeeeee 35%,#eeeeee 65%,#fff 100%);
    background: -webkit-linear-gradient(left,#FFF 0,#eeeeee 35%,#eeeeee 65%,#FFF 100%);
    background: -moz-linear-gradient(left,#fff 0,#eeeeee 35%,#eeeeee 65%,#fff 100%);
    background: -webkit-gradient(linear,left top,right top,color-stop(0%,#FFF),color-stop(35%,#eeeeee),color-stop(65%,#eeeeee),color-stop(100%,#FFF));
}
.next-article:after {
    display: block !important;
    content: "";
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0;
    position: absolute;
    z-index: 1;
    background: linear-gradient(to right,#FFF 0,#eeeeee 35%,#eeeeee 65%,#FFF 100%);
    background: -ms-linear-gradient(left,#fff 0,#eeeeee 35%,#eeeeee 65%,#fff 100%);
    background: -o-linear-gradient(left,#fff 0,#eeeeee 35%,#eeeeee 65%,#fff 100%);
    background: -webkit-linear-gradient(left,#FFF 0,#eeeeee 35%,#eeeeee 65%,#FFF 100%);
    background: -moz-linear-gradient(left,#fff 0,#eeeeee 35%,#eeeeee 65%,#fff 100%);
    background: -webkit-gradient(linear,left top,right top,color-stop(0%,#FFF),color-stop(35%,#eeeeee),color-stop(65%,#eeeeee),color-stop(100%,#FFF));
}
