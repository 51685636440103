
.cart-wrapper .table tbody td {
    border-top: 0;
    vertical-align: middle;
    padding-top: 10px;
}

.input-code {
    width: 200px;
    height: 60px;
    line-height: 60px; 
    @include media-breakpoint-down(md) {
        width: 165px; 
    }
}

