.email-message {
    a {
        margin-bottom: 3px;
        padding: 20px 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 60px;
        position: relative;
        @include media-breakpoint-down(md) {padding: 20px 10px; height: auto; display: block;}
        .form-check {
            display: inline-block;
            position: relative;
            @include media-breakpoint-down(md) {display: none;}
        }
        .email-user {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 226px;
            flex: 0 0 226px;
            width: 226px;
            padding: 0 20px 0 10px;
            .btn-round-xss {
                @include media-breakpoint-down(md) {display: none;}
            }
            h6 {
                @include media-breakpoint-down(md) {margin-left: 4px;}   
            }
            @include media-breakpoint-down(lg) {
                flex: 0 0 180px;
                width: 180px;
                padding: 0 20px 0 0px;
            }
            @include media-breakpoint-down(md) {  
                width: 100%;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                padding: 0;
            }
        }
        .email-subject {position: relative;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 210px;
            flex: 0 0 210px;
            width: 210px;
            padding: 0 20px 0 34px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            color: #44444F;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            @include media-breakpoint-down(md) {display: none;}
        }
        .email-text {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 calc(100% - 552px);
            flex: 0 0 calc(100% - 552px);
            max-width: calc(100% - 552px);
            padding-right: 20px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #92929D;
            @include media-breakpoint-down(md) { 
                max-width: 100%;
                height: 46px;
                margin-top: -10px;
                padding: 0 0 0 47px;
                white-space: normal;
                line-height: 1.66667;
            }
        }
        .email-file {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            font-size: 0;
            @include media-breakpoint-down(md) { 
                display: inline-block;
                position: absolute;
                top: 50px;
                left: 10px;
                width: auto;
            }

        }
        .email-time {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 72px;
            flex: 0 0 72px;
            width: 72px;
            margin-left: auto;
            text-align: right;
            font-size: 12px;
            color: #696974;
            @include media-breakpoint-down(md) { 
                display: inline-block;
                position: absolute;
                top: 14px;
                right: 15px;
                width: auto;
            }
        }
    }
}