.timer {
    display: block;
    position: relative;
    float: left;
    &.style3 {
        .time-count {
            max-width: 25%;
            width: 25%;
            margin:0;
            padding: 0px; 
            text-align: left;

            .text-time {
                font-size: 40px; 
                font-weight: 600;
                color: #fff;
                margin-left:0 !important;
                margin-right:20px !important;
                margin-bottom:0 !important;
                text-align: left;
                background: transparent;
                padding-bottom: 0 !important;
                padding-left: 0 !important;
                @include respond("lg") {
                    font-size: 30px;
                }
            }
            .text-day {
                text-align: left;
                margin-left: 0;
                font-size: 14px; 
                font-weight: 400;
                color: #eee;
                margin-top: 0 !important;
                margin-bottom: 10px; 
            }
        }
    }
    &.bg-white {
        .time-count {
            max-width: 25%;
            width: 25%;
            margin:0;
            padding: 10px; 
            .text-time {
                font-size: 30px; 
                font-weight: 700;
                color: #000;
                text-align: center;
                padding-bottom: 0 !important;
            }
            .text-day {
                text-align: center;
                margin-left: 0;
                font-size: 14px; 
                font-weight: 700;
                color: #999;
                margin-top: 0 !important;
                margin-bottom: 10px; 
            }
        }
    }
    &.bg-grey-time {
       .text-time {
        background-color: #f5f5f5;
       } 
    }
    .time-count {
        margin:8px;
        max-width: 60px; 
        text-align: center; 
        float: left;
        margin-left: 0;
    }
    .text-time {
        padding: 12px;
        margin:5px;
        font-weight: 600;
        font-size:15px;
        background-color: #fff;
        border-radius: 5px;
    }
    .text-day {
        display: block;
        text-align: center;
        margin-top: 10px; 
        font-size: 12px; 
        font-weight: 600; 
        margin-left: 6px;
    }
}