//  DARK THEME
:root {--f7-theme-color: #132977;}


.theme-dark {
    background-color: #132977;

    .middle-sidebar-header,
    .middle-sidebar-right-content,.nav-header,.navigation {background-color: #293145 !important; box-shadow:none !important;}
    .navigation .nav-content ul li:not(.logo) a span {color: #ddd;}
    .navigation .nav-top a i {  background-color: transparent !important;}
    .scroll-bar::-webkit-scrollbar,
    .scroll-bar::-webkit-scrollbar-track { background-color: #1a2236 !important;}
    .main-content .middle-sidebar-header .header-menu:before,
    .main-content .middle-sidebar-header .header-menu:after {background-color: #fff;}
    .main-content .middle-sidebar-header .menu-search-icon i {color: #fff !important;   }
    .main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content .card {border:0 !important;}
    .main-content .middle-sidebar-header {border-bottom: 0;}

    .modal-popup-chat .modal-popup-wrap .modal-popup-header,
    .modal-popup-chat .modal-popup-wrap .modal-popup-footer {border:0 !important;}

    .input-file + .js-labelFile.btn-tertiary,
    .modal-popup-chat .modal-popup-wrap,
    .theme-dark-bg {background-color: #1a2236 !important;}
    .theme-light-bg {background-color: #132977 !important;}
    .theme-white-bg {background-color: #eee !important;}

    ul.list-inline .list-inline-item.border-bottom {border-bottom: 0 !important;}



    .form-control {background-color: #333 !important;   border-color:var(--theme-color); }


    
    .dropdown-menu-settings:after {border-color: transparent transparent #1a2236 transparent;}
    .bg-transparent-card {background:transparent !important;}
    .dropdown-menu-settings,
    .main-content {background-color: #1a2236;}
    .form-group.bg-white {border:0 !important;background-color: #1a2236 !important;}
    .main-content .left-sidebar .left-sidebar-content .left-sidebar-header,
    .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom,
    .main-content .left-sidebar .left-sidebar-content {background-color: #293145 !important;}
    
    .apexcharts-datalabel-value {fill:#eee;}


    .form-group label,
    .text-dark {color: #eee !important;} 
    
    .member-ul li.active a b,
    .price-wrap .left-price h2,
    .form-div h2,
    .slide-wrapper h2 {color: #eee;}
    .header-wrapper .search-div {border:1px #555 solid;}
    .header-wrapper .nav-menu li a {color: #ddd !important;}
    .search-wrapper,
    .accordion .card .card-body,
    .accordion .card .card-header,
    .form-div {background-color: #293145;}

    .header-wrapper .logo {color: lightsteelblue;}
    
    .card p {color: #ddd !important;}
    .accordion .card button:after,
    .accordion .card button,
    .price-wrap .middle-price h6,
    .form-div p,
    .form-div label p,
    .form-div .sign-up-text {color: #ddd;}

    .form-div label p a,
    .form-div .sign-up-text a {color: #fff; margin-left: 5px; }
    .navbar-toggler {color: rgba(255, 255, 255, 1);border-color: rgba(255, 255, 255, 1); }

    .accordion .card button.small-text a,
    .accordion .card button.small-text {color: #fff;}
    .card-header {border-bottom: 0 solid #000;}

    .dark-bg-transparent {background-color: transparent !important;}
    .nav-tabs li a.active {
        border-bottom: 2px #ddd solid !important;
        color: #fff !important;
    }
    .form-group label.text-dark-color,
    .text-dark-color {color: #111 !important;}

    .bor-0 {border:0 !important;}
    .chatlist.style2 .item img {border:2px #1a2236 solid;}
    .card-body.border-top-xs {border-top: 1px solid #555;}

    .nav-header .nav-menu:before,
    .nav-header .nav-menu:after,
    .nav-header .nav-menu {background-color: #fff;}
    a.text-dark:hover {color: #eee !important;} 


}

.theme-dark .header-wrapper .logo .light {display: none;}
.theme-dark .header-wrapper .logo .dark {display: inline-block;}

