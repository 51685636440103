.default-font {
   font-family:
      'Work Sans',
      IRANYekan,
      IRANYekanDigits,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Helvetica,
      Arial,
      sans-serif;
}
